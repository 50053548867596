import React from 'react';

import { graphql } from 'gatsby';

import { Layout } from '../components/layout/layout';
import { Container } from '../components/layout/container';
import { Link } from '../components/link';
import bgImage from '../images/bg-404.png';

function PageNotFound({ data: { navbar } }) {
  return (
    <Layout navbar={navbar}>
      <div
        style={{ backgroundImage: `url(${bgImage})` }}
        className="h-full lg:h-screen bg-center bg-no-repeat bg-cover"
      >
        <Container>
          <section className="py-20 four-zero-four-page text-white">
            <div className="w-full lg:w-3/4">
              <sub className="uppercase font-semibold text-xs">Error 404</sub>
              <h1 className="font-semibold text-4xl mb-12">This page can&rsquo;t be found</h1>
              <p>
                But that doesn&rsquo;t mean the content you&rsquo;re after doesn&rsquo;t exist...
                <br />
                <br />
                Roughly 85% of our universe is made up of{' '}
                <Link
                  className="underline hover:no-underline"
                  to="https://home.cern/science/physics/dark-matter"
                  attrs={{ target: '_blank' }}
                >
                  dark matter↗
                </Link>{' '}
                &ndash; it doesn&rsquo;t absorb, reflect or emit light, making it very hard to find. That being said,
                astrophysics is a bit out of scope with what we do here at MATTR. If you&rsquo;re wanting to learn more
                about a new world of data and digital trust, here are some starting points:
              </p>
              <ul className="my-8">
                <li>
                  <Link to="/" className="underline hover:no-underline">
                    MATTR homepage
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://learn.mattr.global/"
                    attrs={{ target: '_blank' }}
                    className="underline hover:no-underline"
                  >
                    MATTR Learn ↗
                  </Link>
                </li>
              </ul>
              <p>
                Hopefully you would be able to find what you were looking for elsewhere on our sites, otherwise please{' '}
                <Link to="/contact" className="underline hover:no-underline">
                  contact
                </Link>{' '}
                us and we&rsquo;d be more than happy to help.
              </p>
            </div>
          </section>
        </Container>
      </div>
    </Layout>
  );
}

export default PageNotFound;

export const pageQuery = graphql`
  query Marketing404Page($category: String!) {
    navbar: datoCmsNavbar(category: { eq: $category }) {
      ... on DatoCmsNavbar {
        ...Navbar
      }
    }
  }
`;
